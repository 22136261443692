import { SVGProps, Ref, forwardRef } from 'react'

const SvgPhoneLock = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15 15.75v4.768a2.73 2.73 0 0 1-2.733 2.732H5.733A2.731 2.731 0 0 1 3 20.518V4.983A2.733 2.733 0 0 1 5.733 2.25H9M3 18.75h12"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.5 5.25h-6a1.5 1.5 0 0 0-1.5 1.5v4.5a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5v-4.5a1.5 1.5 0 0 0-1.5-1.5ZM16.5.75a3 3 0 0 0-3 3v1.5h6v-1.5a3 3 0 0 0-3-3Z"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M16.5 9.405a.375.375 0 0 1 0-.75M16.5 9.405a.375.375 0 0 0 0-.75"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgPhoneLock)
export default ForwardRef
